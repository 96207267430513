import React from "react"
import search from "./search"
import { useStaticQuery, graphql } from "gatsby"
const SearchCategoryById = id => {
  const { allSanityCategory } = useStaticQuery(graphql`
    query allSearchCategoryById {
      allSanityCategory(sort: { order: ASC, fields: [title] }) {
        nodes {
          id
          title
          slug
          referencedCategories {
            id
          }
          icon {
            asset {
              fluid {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
              }
              fixed(height: 25, width: 25) {
                base64

                width
                height
                src
                srcSet
                srcWebp
                srcSetWebp
              }
            }
          }
        }
      }
    }
  `)
  return search(allSanityCategory.nodes, "id", id)
}

export default SearchCategoryById
