// ** @jsx jsx *
import React, { useState, useEffect } from "react"
import Layout from "../components/Layout"
import Header from "../components/ui/Header"
import DownBar from "../components/ui/DownBar"
import ProductList from "../components/ProductList"
import { jsx, Message, Text, useThemeUI } from "theme-ui"
import Img from "gatsby-image"
import countapi from "countapi-js"
import slug from "../lib/slug"
import SearchCategoryById from "../lib/SearchCategoryById.jsx"
import CategoriesSnacks from "../components/CategoriesSnacks.jsx"
import ReactPixel from "../lib/react-facebook-pixel"
import { useStaticQuery, graphql } from "gatsby"
const CategoryTemplate = ({ pageContext }) => {
  const { sanityIntegration } = useStaticQuery(graphql`
    query CategoryPixelFacebook {
      sanityIntegration {
        facebookPixel {
          pixelId
          currency
        }
      }
    }
  `)
  const [hiddenMenu, setHiddenMenu] = useState(false)
  const validateReferencedCategories =
    pageContext.category &&
    pageContext.category.referencedCategories &&
    pageContext.category.referencedCategories.length > 0

  const subCategories = validateReferencedCategories
    ? pageContext.category.referencedCategories.map(category => {
        if (category) {
          return SearchCategoryById(category.id)
        }
      })
    : []
  const useTheme = useThemeUI()
  const colorBackground =
    useTheme &&
    useTheme.theme &&
    useTheme.theme.colors &&
    useTheme.theme.colors.background
      ? useTheme.theme.colors.background.replace("#", "")
      : "ffffff"

  const imgTransform =
    pageContext &&
    pageContext.category &&
    pageContext.category.icon &&
    pageContext.category.icon.asset &&
    pageContext.category.icon.asset.url
      ? `${pageContext.category.icon.asset.url}/?bg=${colorBackground}&h=250&w=250`
      : ""

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      window.location &&
      window.location.hostname
    ) {
      countapi
        .hit(
          slug(window.location.hostname),
          "category",
          slug(pageContext.category.id)
        )
        .then(result => {
          console.log(result.value)
        })
        .catch(e => console.log(e))

      //pixel facebook
      if (
        sanityIntegration &&
        sanityIntegration.facebookPixel &&
        sanityIntegration.facebookPixel &&
        sanityIntegration.facebookPixel.pixelId
      ) {
        ReactPixel.track("ViewContent", {
          whatshop_content_type: "category",
          whatshop_content_id: pageContext.category.id,
          whatshop_content_title: pageContext.category.title,
        })
      }
    }
  }, [])
  return (
    <Layout
      seo={{
        title: pageContext.category.title,
        image: imgTransform,
      }}
    >
      <Header prevPage="/" />
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          sx={{
            width: ["width1", "width2", "width3"],
            marginTop: 3,
            marginBottom: 2,
            backgroundColor: "transparent",
          }}
        >
          <Message
            variant="primary"
            sx={{
              fontWeight: "bold",
              textTransform: "uppercase",
              backgroundColor: "background",
              color: "primary",
              boxShadow: "flat",
              fontSize: 3,
              paddingTop: 2,
              paddingBottom: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>{pageContext.category.title}</div>
            {pageContext.category.icon &&
              pageContext.category.icon.asset &&
              pageContext.category.icon.asset.fixed && (
                <Img
                  fixed={pageContext.category.icon.asset.fixed}
                  sx={{ width: 25 }}
                  alt={pageContext.category.title}
                />
              )}
          </Message>
          {subCategories.length > 0 && (
            <div>
              {/* <Text variant="caption" mb={1}>
                Categorías relacionadas:
              </Text> */}
              <div sx={{ variant: "div.flex" }}>
                <CategoriesSnacks
                  newCategories={subCategories}
                  overflowX
                  removeViewAll
                />
              </div>
            </div>
          )}
        </div>
        <ProductList
          products={pageContext.products}
          money={pageContext.sanitySetting.money}
        />
      </div>
      {pageContext.activeCart && <DownBar />}
    </Layout>
  )
}
export default CategoryTemplate
